import { Form } from '@unform/web';
import React, { useRef } from 'react';
import InputV2 from '../shared/InputV2/InputV2';
import * as Yup from 'yup';
import Textarea from '../shared/Textarea/Textarea';
import ReCAPTCHA from 'react-google-recaptcha';
import { toast } from 'react-toastify';
import { contactSellers } from '../services/LandingPage';

function SupportForm() {
  const formRef = useRef();
  const recaptchaRef = useRef();

  async function verifyFormData(field) {
    try {
      const dataToContact = Yup.object().shape({
        name: Yup.string()
          .required('Informe seu nome ou razão social')
          .min(3)
          .max(50),
        email: Yup.string()
          .required('Informe o assunto que deseja tratar.')
          .min(3)
          .max(50),
        description: Yup.string()
          .required('Informe uma descrição para o seu suporte.')
          .min(3)
          .max(125),
      });
      await dataToContact.validate(field, { abortEarly: false });
      formRef.current.setErrors({});
      handleSupportInfos();
      return true;
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errorMessages = {};
        error.inner.forEach((error) => {
          errorMessages[error.path] = error.message;
        });
        formRef.current.setErrors(errorMessages);
      }
      return false;
    }

    function handleSupportInfos() {
      const { name, email, description } = formRef.current.getData();
      const params = {
        name,
        city: 'N/A',
        email,
        type_establishment: 'N/A',
        phone: 'N/A',
        subject: 'Chamado página de suporte',
        message: description,
        token: '',
      };
      getRecaptcharToken().then((token) => {
        params.token = token;
        openSupportTicket(params);
      });
    }
  }

  function openSupportTicket(params) {
    contactSellers(params)
      .then((response) => {
        console.log('response', response);
      })
      .catch((error) => {
        console.error('error', error);
      });
  }

  async function getRecaptcharToken() {
    const token = await recaptchaRef.current.executeAsync();
    if (!token) {
      toast.error(
        'Ocorreu um erro ao validar o captcha, entre em contato com o nosso suporte'
      );
      return '';
    }
    return token;
  }

  return (
    <div className="w-100 h-100 bg-white p-2 align-items-center vstack gap-3 py-4">
      <div className="vstack align-items-center vstack gap-1">
        <h2 className="m-0 fs-3 fw-bold text-center">
          Não encontrou o que procura?
        </h2>
        <h3 className="m-0 fs-6 text-center w-50">
          Se precisar de mais assistência, entre em contato através do
          formulário abaixo.
        </h3>
      </div>

      <div className="w-100" style={{ maxWidth: 800 }}>
        <div className="hstack justify-content-center align-items-center">
          <Form ref={formRef} onSubmit={verifyFormData}>
            <div className="d-flex flex-column w-100 gap-2 flex-sm-row mb-2">
              <InputV2
                containerClass="w-100"
                label="Nome/Razão social"
                name="name"
                type="text"
                placeholder="Como podemos te chamar ?"
                maxLength={50}
              />
              <InputV2
                containerClass="w-100"
                label="Email"
                name="email"
                type="email"
                placeholder="Informe seu e-mail"
                maxLength={50}
              />
            </div>

            <Textarea
              label="Descrição"
              name="description"
              type="text"
              maxLength={125}
              labelStyle={{ width: '100%' }}
              className="dl-textarea"
            />

            <button type="submit" className="w-100 btn btn-primary mt-2">
              Enviar Mensagem
            </button>
          </Form>
        </div>
      </div>
      <ReCAPTCHA
        ref={recaptchaRef}
        size="invisible"
        sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_LP}
      ></ReCAPTCHA>
    </div>
  );
}

export default SupportForm;
