import { Form } from '@unform/web';
import React, { useReducer, useRef, useState } from 'react';
import * as Yup from 'yup';
import { Scope } from '@unform/core';
import InputV2 from '../../shared/InputV2/InputV2';
import {
  LOGO_IMAGE_URL,
  onlyNumbers,
  passwordSecurity,
  validStepsPassword,
} from '../../Utils/Index';
import ReCAPTCHA from 'react-google-recaptcha';
import { httpService } from '../../services/HttpService';
import { toast } from 'react-toastify';
import { pincode, recoverPassword } from '../../services/AuthService';

function RecoverPasswordCore({ goBack, isClientRecover }) {
  const formRef = useRef();
  const recaptchaRef = useRef();

  const titlesByStep = {
    1: {
      primary: 'Recuperação de senha',
      secondary: 'Informe o e-mail e telefone cadastrado na sua conta',
    },
    2: {
      primary: 'Digite o código de verificação enviado ao seu e-mail',
      secondary:
        'Para garantir a sua segurança, enviamos um código de verificação para o seu e-mail. Insira-o no campo abaixo para continuar.',
    },
    3: {
      primary: 'Redefina sua senha',
      secondary: 'Crie uma nova senha para sua conta.',
    },
    4: {
      primary: 'Sua senha foi alterada com sucesso!',
      secondary:
        'Agora você pode acessar sua conta com a nova senha. Vá para a página de login para continuar.',
    },
    '4_error': {
      primary: 'Não foi possível alterar a senha',
      secondary:
        'Ocorreu um erro ao alterar a senha da conta, tente novamente ou entre em contato com nosso atendimento',
    },
  };

  const [formValues, updateFormValues] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      email: '',
      phone: '',
      password: '',
      code: '',
      recaptcha: '',
    }
  );

  const [title, setTitle] = useState(titlesByStep[1]);
  const [step, setStep] = useState(1);
  const [displayPassword, setDisplayPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [displayConfirmPassword, setDisplayConfirmPassword] = useState(false);
  const [errorOnUpdate, setErrorOnUpdate] = useState(false);
  const [errorAcm, setErrorAcm] = useState(1);
  const [passwordSecurityStatus, setPasswordSecurityStatus] = useState({
    label: null,
    color: null,
  });

  async function handleSubmit(stepData) {
    if (step === 3) {
      callUpdateAccountPassword();
      handleFormValues(true);
      return;
    }

    if (step === 4) {
      errorOnUpdate && errorAcm <= 3
        ? callUpdateAccountPassword(formValues.password)
        : goBack();
      return;
    }

    setLoading(true);
    const isValid = await verifyFormValues(step, stepData);
    setLoading(false);
    if (!isValid) return;
    handleFormValues();
  }

  function backward() {
    if (step === 1 || step === 4) {
      goBack();
      return;
    }
    setStep(step - 1);
  }

  function forward() {
    const nextStep = step + 1;
    setTitle(titlesByStep[nextStep]);
    setStep(nextStep);
  }

  function handleFormValues(onUpdateValues = false) {
    const scope = { 1: 'store', 2: 'code', 3: 'password' };
    const values = formRef.current.getData()[scope[step]];
    if (values?.phone) values.phone = onlyNumbers(values.phone);
    updateFormValues(values);
    if (step === 1) {
      callSendPincode();
      return;
    }
    if (onUpdateValues) return;
    forward();
  }

  Yup.addMethod(Yup.string, 'handleCheckPincode', function (errorMessage) {
    return this.test(`handle-check-pincode`, errorMessage, async (code) => {
      setLoading(true);
      const { email, phone } = formValues;
      return new Promise((resolve) => {
        httpService(
          isClientRecover ? 'consumer/user' : 'painel/gestor',
          'check-pincode',
          {
            isClientRecover,
            pincode: code,
            phone,
            email,
          }
        )
          .then(() => {
            handleFormValues();
            resolve(true);
            setLoading(false);
          })
          .catch((error) => {
            resolve(false);
            setLoading(false);
          });
      });
    });
  });

  async function verifyFormValues(step, field) {
    try {
      const store = Yup.object().shape({
        store: Yup.object().shape({
          email: Yup.string()
            .required('E-mail é obrigatório')
            .matches(
              /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
              'Email inválido'
            ),
          phone: Yup.string()
            .required('Telefone é obrigatório')
            .matches(/[0-9]{2}\s[0-9]{5}-[0-9]{4}/, 'Telefone inválido'),
        }),
      });

      const code = Yup.object().shape({
        code: Yup.object().shape({
          code: Yup.string()
            .required('Informe o código de segurança')
            .min(6, 'Código inválido')
            .max(6, 'Código inválido')
            .handleCheckPincode('Código inválido'),
        }),
      });

      const password = Yup.object().shape({
        password: Yup.object().shape({
          password: Yup.string()
            .required('Senha é obrigatória')
            .min(6, 'A senha não atinge os requisitos mínimos de segurança'),
          password_confirmation: Yup.string()
            .required('Confirme sua senha')
            .oneOf([Yup.ref('senha'), 'Teste'], 'As senhas não conferem'),
        }),
      });

      let schema;
      if (step === 1) schema = store;
      if (step === 2) schema = code;
      if (step === 3) schema = password;
      await schema.validate(field, { abortEarly: false });
      formRef.current.setErrors({});
      return true;
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errorMessages = {};
        error.inner.forEach((error) => {
          errorMessages[error.path] = error.message;
        });
        formRef.current.setErrors(errorMessages);
      }
      return false;
    }
  }

  function callSendPincode() {
    setLoading(true);
    const {
      store: { phone, email },
    } = formRef.current.getData();
    pincode(isClientRecover, onlyNumbers(phone), email)
      .then(() => {
        forward();
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error('SEND_PINCODE', error);
        toast.error(
          'Ocorreu um erro ao enviar o pincode, tente novamente ou entre em contato com o nosso suporte'
        );
      });
  }

  function handlePasswordSecurity(password) {
    let acmSecurity = 0;
    const validatorsPassword = validStepsPassword(password, true);
    Object.keys(validatorsPassword).forEach((validator) => {
      if (validatorsPassword[validator]) acmSecurity++;
    });
    setPasswordSecurityStatus(passwordSecurity(acmSecurity));
  }

  function callUpdateAccountPassword() {
    setLoading(true);
    const { email, code, phone, password } = formValues;
    const passwordValue = password
      ? password
      : formRef.current.getData()?.password?.password;
    recoverPassword(isClientRecover, passwordValue, code, phone, email)
      .then(() => {
        setErrorOnUpdate(false);
        setTitle(titlesByStep[4]);
      })
      .catch((error) => {
        setErrorOnUpdate(true);
        setTitle(titlesByStep['4_error']);
        setErrorAcm(errorAcm + 1);
        console.error(`${new Date().toISOString()}|UPDATE_PASSWORD`, error);
      })
      .finally(() => {
        setStep(4);
        setLoading(false);
      });
  }

  return (
    <Form
      ref={formRef}
      onSubmit={handleSubmit}
      className={`w-100 ${loading ? 'pe-none opacity-50' : ''} ${
        isClientRecover ? 'p-2' : ''
      }`}
      style={{ maxWidth: isClientRecover ? '' : 500 }}
    >
      {!isClientRecover && (
        <img
          src={LOGO_IMAGE_URL}
          alt="Logotipo do Delivery.Legal"
          width={300}
          className="mb-5"
        />
      )}

      <div className="vstack">
        <div className="vstack gap-1 mb-3">
          <h3 className="fw-bold fs-5 text-center m-0">{title.primary}</h3>
          <span className="fs-6 text-center">{title.secondary}</span>
        </div>

        {step === 1 && (
          <Scope path="store">
            <div className="vstack gap-4">
              <InputV2
                name="email"
                type="email"
                label="E-mail"
                placeholder="E-mail"
                maxLength={50}
              />

              <InputV2
                label="WhatsApp/Telefone"
                name="phone"
                type="text"
                placeholder="WhatsApp/Telefone"
                mask={'PHONE'}
                maxLength={13}
              />
            </div>
          </Scope>
        )}

        {step === 2 && (
          <Scope path="code">
            <div className="vstack gap-4">
              <InputV2
                name="code"
                type="text"
                label="Código"
                placeholder="Informe o código"
                maxLength={10}
              />
            </div>
          </Scope>
        )}

        {step === 3 && (
          <Scope path="password">
            <div className="vstack gap-4">
              <InputV2
                label="Senha"
                name="password"
                type={displayPassword ? 'text' : 'password'}
                placeholder="Informa sua nova senha"
                onUpdateValue={(text) => handlePasswordSecurity(text)}
                containerClass="position-relative"
                maxLength={75}
              >
                <button
                  type="button"
                  className="z-2 btn btn-primary-fill p-0 m-0 position-absolute d-flex align-items-center justify-content-center p-1 "
                  style={{ top: 30, right: 4 }}
                  onClick={() => setDisplayPassword(!displayPassword)}
                >
                  <span className="material-symbols-outlined fs-5">
                    {displayPassword ? 'visibility' : 'visibility_off'}
                  </span>
                </button>
              </InputV2>

              <InputV2
                label="Confirme sua senha"
                name="password_confirmation"
                type={displayConfirmPassword ? 'text' : 'password'}
                placeholder="Confirme sua nova senha"
                containerClass="position-relative"
                maxLength={75}
              >
                <button
                  type="button"
                  className="z-2 btn btn-primary-fill p-0 m-0 position-absolute d-flex align-items-center justify-content-center p-1 "
                  style={{ top: 30, right: 4 }}
                  onClick={() =>
                    setDisplayConfirmPassword(!displayConfirmPassword)
                  }
                >
                  <span className="material-symbols-outlined fs-5">
                    {displayConfirmPassword ? 'visibility' : 'visibility_off'}
                  </span>
                </button>
              </InputV2>
            </div>

            {passwordSecurityStatus.label && (
              <div className="w-100 text-center">
                <span
                  className="fs-7 fw-bold text-center"
                  style={{ color: passwordSecurityStatus.color }}
                >
                  {passwordSecurityStatus.label}
                </span>
              </div>
            )}
          </Scope>
        )}
      </div>

      <div className="d-flex flex-column gap-2 my-4">
        <div
          className="my-2 w-100 justify-content-center"
          style={{
            display: step === 1 ? 'flex' : 'none',
          }}
        >
          <ReCAPTCHA
            ref={recaptchaRef}
            size="normal"
            sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_CREATE_ACCOUNT}
            onChange={(recaptcha) => updateFormValues({ recaptcha })}
            onExpired={() => updateFormValues({ recaptcha: '' })}
            onError={() => updateFormValues({ recaptcha: '' })}
          ></ReCAPTCHA>
        </div>

        <button
          type="submit"
          className={`btn btn-primary ${
            !formValues.recaptcha ? 'disabled-btn' : ''
          }`}
          disabled={!formValues.recaptcha}
        >
          {step < 4
            ? 'Próximo'
            : errorOnUpdate
            ? 'Tentar novamente'
            : 'Ir para página de login'}
        </button>

        <button
          type="button"
          className="btn btn-outline-primary"
          onClick={() => backward()}
        >
          {step === 1 ? 'Sair' : step === 4 ? 'Sair' : 'Voltar'}
        </button>
      </div>
    </Form>
  );
}

export default RecoverPasswordCore;
