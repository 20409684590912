import moment from 'moment';
import { useEffect, useState } from 'react';
import {
  calculateDeliveryPrice,
  verifyStatusStore,
  verifyToken,
} from '../services/Consumer';
import { callBrasilApi } from '../services/HttpService';
import EventEmitter from '../services/Event';
import { v4 as uuidv4 } from 'uuid';
import { toast } from 'react-toastify';
import { Buffer } from 'buffer';
import {
  IconAleloPayment,
  IconAmexPayment,
  IconDinersPayment,
  IconDiscoverPayment,
  IconHiperCardPayment,
  IconJCBPayment,
  IconMasterCardPayment,
  IconMastroPayment,
  IconVisaPayment,
} from '../assets/Images/payments/IconsPayment';
Buffer.from('anything', 'base64');

export function maskCPF(value) {
  value = value.replace(/\D/g, '');
  value = value.replace(/(\d{3})(\d)/, '$1.$2'); // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
  value = value.replace(/(\d{3})(\d)/, '$1.$2');
  value = value.replace(/(\d{3})(\d{1,2})/, '$1-$2');
  value = value.replace(/(-\d{2})\d+?$/, '$1'); // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
  return value;
}

export function maskCNPJ(value) {
  value = value.replace(/\D/g, '');
  value = value.replace(/(\d{2})(\d)/, '$1.$2');
  value = value.replace(/(\d{3})(\d)/, '$1.$2');
  value = value.replace(/(\d{3})(\d)/, '$1/$2');
  value = value.replace(/(\d{4})(\d)/, '$1-$2');
  return value;
}

export function maskDocument(value) {
  if (!value) return '';
  return onlyNumbers(value).length > 11 ? maskCNPJ(value) : maskCPF(value);
}

export function validCpf(cpf) {
  if (!cpf?.length) return false;
  cpf = cpf.replace(/[^\d]+/g, '');
  if (cpf === '') return false;
  // Elimina CPFs invalidos conhecidos
  if (
    cpf.length !== 11 ||
    cpf === '00000000000' ||
    cpf === '11111111111' ||
    cpf === '22222222222' ||
    cpf === '33333333333' ||
    cpf === '44444444444' ||
    cpf === '55555555555' ||
    cpf === '66666666666' ||
    cpf === '77777777777' ||
    cpf === '88888888888' ||
    cpf === '99999999999'
  )
    return false;
  // Valida 1o digito
  let add = 0;
  for (let i = 0; i < 9; i++) add += parseInt(cpf.charAt(i)) * (10 - i);
  let rev = 11 - (add % 11);
  if (rev === 10 || rev === 11) rev = 0;
  if (rev !== parseInt(cpf.charAt(9))) return false;
  // Valida 2o digito
  add = 0;
  for (let i = 0; i < 10; i++) add += parseInt(cpf.charAt(i)) * (11 - i);
  rev = 11 - (add % 11);
  if (rev === 10 || rev === 11) rev = 0;
  if (rev !== parseInt(cpf.charAt(10))) return false;
  return true;
}

export function validCNPJ(cnpj) {
  cnpj = cnpj.replace(/[^\d]+/g, '');

  if (cnpj === '') return false;

  if (cnpj.length !== 14) return false;

  // Elimina CNPJs invalidos conhecidos
  if (
    cnpj === '00000000000000' ||
    cnpj === '11111111111111' ||
    cnpj === '22222222222222' ||
    cnpj === '33333333333333' ||
    cnpj === '44444444444444' ||
    cnpj === '55555555555555' ||
    cnpj === '66666666666666' ||
    cnpj === '77777777777777' ||
    cnpj === '88888888888888' ||
    cnpj === '99999999999999'
  )
    return false;

  // Valida DVs
  let tamanho = cnpj.length - 2;
  let numeros = cnpj.substring(0, tamanho);
  let digitos = cnpj.substring(tamanho);
  let soma = 0;
  let pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) pos = 9;
  }
  let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado != digitos.charAt(0)) return false;

  tamanho = tamanho + 1;
  numeros = cnpj.substring(0, tamanho);
  soma = 0;
  pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) pos = 9;
  }
  resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado != digitos.charAt(1)) return false;

  return true;
}

export function maskPhoneBR(value) {
  value = value.replace(/\D/g, '');
  value = value.replace(/(\d{2})(\d)/, '$1 $2');
  value = value.replace(/(\d)(\d{4})$/, '$1-$2');
  return value;
}

export function maskPhoneInternational(value) {
  value = value.replace(/\D/g, '');
  value = value.replace(/^(\d{1})/, '+1 ');
  value = value.replace(/(\d{3})(\d)/, '($1) $2');
  value = value.replace(/(\d)(\d{3})$/, '$1-$2');
  return value;
}

export function validEmail(value) {
  const reg = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
  return reg.test(value);
}

export function maskCEP(value) {
  value = value.replace(/\D/g, '');
  value = value.replace(/^(\d{5})(\d)/, '$1-$2');
  return value;
}

export function maskCurrencyBRL(value) {
  if (typeof value === 'number')
    return new Intl.NumberFormat('pt-br', {
      style: 'currency',
      currency: 'BRL',
    }).format(value);
  else return value;
}

export function maskCurrencyBRLWithoutSymbol(value) {
  if (typeof value === 'number')
    return new Intl.NumberFormat('pt-br', {
      style: 'currency',
      currency: 'BRL',
    })
      .format(value)
      .replace('R$', '');
  else return value;
}

export function maskCurrencyBRLWithoutSymbolString(value) {
  value = value.replace(/\D/g, '');
  value = value.replace(/(\d)/, '$1');
  value = value.replace(/(\d)(\d{2})$/, '$1,$2');
  value = value.replace(/(?=(\d{3})+(\D))\B/g, '.');
  return value;
}

export function maskCurrencyBRLWithSymbolString(value) {
  value = String(value);
  value = value.replace(/\D/g, '');
  value = value.replace(/(\d)/, 'R$ $1');
  value = value.replace(/(\d)(\d{2})$/, '$1,$2');
  value = value.replace(/(?=(\d{3})+(\D))\B/g, '.');
  return value;
}

export function maskCardNumber(value) {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{4})(?=\d)/g, '$1 ')
    .trim();
}

export function validateExpiryDate(value) {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d{4})$/, '$1/$2')
    .replace(/(\/\d{4})\d+?$/, '$1');
}

export function onlyNumbers(value) {
  return value.replace(/\D/g, '');
}

export function validStepsPassword(value, returnSteps = false) {
  const upperCaseReg = /(?=.*[A-Z])/;
  const lowerCaseReg = /(?=.*[a-z])/;
  const hasNumberReg = /(?=.*[0-9])/;
  const hasSymbolReg = /(?=.*[^A-Za-z0-9])/;
  const minLengthReg = /(?=.{8,})/;

  let validPassword = {
    upperCase: upperCaseReg.test(value),
    lowerCase: lowerCaseReg.test(value),
    hasNumber: hasNumberReg.test(value),
    hasSymbol: hasSymbolReg.test(value),
    minLength: minLengthReg.test(value),
  };
  let stepsValid = {
    upperCase: upperCaseReg.test(value),
    lowerCase: lowerCaseReg.test(value),
    hasNumber: hasNumberReg.test(value),
    hasSymbol: hasSymbolReg.test(value),
    minLength: minLengthReg.test(value),
  };
  return returnSteps ? stepsValid : validPassword;
}

export function passwordSecurity(securityStep) {
  if (securityStep < 2) return { label: 'Senha frágil', color: '#E4001B' };
  if (securityStep <= 4) return { label: 'Senha razoável', color: '#E46E00' };
  if (securityStep === 5) return { label: 'Senha forte', color: '#27AE60' };
  return { label: '', color: '' };
}

export function validPassword(value) {
  const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})/;
  return regex.test(value);
}

export function removeMaskPrice(value) {
  const val = String(value);
  return parseFloat(val.replace('R$', '').replace('.', '').replace(',', '.'));
}

export function getStateByInitials(state, returnList = false) {
  const infsStates = [
    { codigo: 1, initials: 'AC', nome: 'Acre' },
    { codigo: 2, initials: 'AL', nome: 'Alagoas' },
    { codigo: 3, initials: 'AP', nome: 'Amapá' },
    { codigo: 4, initials: 'AM', nome: 'Amazonas' },
    { codigo: 5, initials: 'BA', nome: 'Bahia' },
    { codigo: 6, initials: 'CE', nome: 'Ceará' },
    { codigo: 7, initials: 'DF', nome: 'Distrito Federal' },
    { codigo: 8, initials: 'ES', nome: 'Espírito Santo' },
    { codigo: 9, initials: 'GO', nome: 'Goiás' },
    { codigo: 10, initials: 'MA', nome: 'Maranhão' },
    { codigo: 11, initials: 'MT', nome: 'Mato Grosso' },
    { codigo: 12, initials: 'MS', nome: 'Mato Grosso do Sul' },
    { codigo: 13, initials: 'MG', nome: 'Minas Gerais' },
    { codigo: 14, initials: 'PA', nome: 'Pará' },
    { codigo: 15, initials: 'PB', nome: 'Paraíba' },
    { codigo: 16, initials: 'PR', nome: 'Paraná' },
    { codigo: 17, initials: 'PE', nome: 'Pernambuco' },
    { codigo: 18, initials: 'PI', nome: 'Piauí' },
    { codigo: 19, initials: 'RJ', nome: 'Rio de Janeiro' },
    { codigo: 20, initials: 'RN', nome: 'Rio Grande do Norte' },
    { codigo: 21, initials: 'RS', nome: 'Rio Grande do Sul' },
    { codigo: 22, initials: 'RO', nome: 'Rondônia' },
    { codigo: 23, initials: 'RR', nome: 'Roraima' },
    { codigo: 24, initials: 'SC', nome: 'Santa Catarina' },
    { codigo: 25, initials: 'SP', nome: 'São Paulo' },
    { codigo: 26, initials: 'SE', nome: 'Sergipe' },
    { codigo: 27, initials: 'TO', nome: 'Tocantins' },
    { codigo: 28, initials: '', nome: '' },
  ];
  const stateInfo = infsStates.find((item) => item.initials === state);
  if (!returnList && stateInfo) return stateInfo;
  if (!returnList && stateInfo) return { codigo: 27, initials: '', nome: '' };
  return infsStates;
}

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return windowDimensions;
}

export function formatHours(time) {
  let timeSplit = time.split('');
  let hasZero = time.split('').map((el) => el !== '0' && el !== ':');

  if (hasZero[0])
    return `${timeSplit[0]}${timeSplit[1]}:${timeSplit[3]}${timeSplit[4]} h`; //10:00
  else if (hasZero[1])
    return `${timeSplit[1]}:${timeSplit[3]}${timeSplit[4]} h`; //01:00
  else if (hasZero[3]) return `${timeSplit[3]}${timeSplit[4]} min`; //00:10
  else if (hasZero[4]) return `${timeSplit[4]} min`; //00:01
}

export function formatDateTime(dateTime) {
  //2021-07-23T21:58:43.000Z -> 23/07/2021 - 21:58:43
  let value,
    hour,
    date = '';
  value = dateTime.split('T');
  date = value[0].split('-');
  date = `${date[2]}/${date[1]}/${date[0]}`;
  hour = value[1].substring(0, 5);
  return `${date} - ${hour}`;
}

export async function getDataBrasilApi(type, param, isV1 = true) {
  const resp = await callBrasilApi(type, param, isV1);
  return resp;
}

export function updateStatusOrder(
  idOrder,
  idNewStatusOrder,
  historyStatusOrder
) {
  let labelStatus,
    timeStatus = '';
  const time = `${moment().format('YYYY-MM-DD')}T${moment().format(
    'HH:mm:ss'
  )}.000Z`;
  const formatedTime = formatDateTime(time);

  switch (idNewStatusOrder) {
    case 2:
      labelStatus = 'Em preparo';
      timeStatus = { dh_inicio: time };
      break;
    case 3:
      labelStatus = 'Pronto para retirada';
      break;
    case 4:
      labelStatus = 'Saiu para entrega';
      break;
    case 5:
      labelStatus = 'Finalizado';
      timeStatus = { dh_concluido: time };
      break;
    case 6:
      labelStatus = 'Cancelado pelo cliente';
      timeStatus = { dh_concluido: time };
      break;
    case 7:
      labelStatus = 'Cancelado pelo estabelecimento';
      timeStatus = { dh_concluido: time };
      break;
    case 8:
      labelStatus = 'Recusado pelo estabelecimento';
      timeStatus = { dh_concluido: time };
      break;
    default:
      break;
  }
  historyStatusOrder.unshift({
    status: labelStatus,
    cod_pedido_status: idNewStatusOrder,
    datahora: time,
    time_formated: formatedTime,
  });
  const statusOrder = {
    codigo: idOrder,
    cod_pedido_status: idNewStatusOrder,
    historico: historyStatusOrder,
    last_status: { status: labelStatus, time: formatedTime },
    status: labelStatus,
    edited: false,
    force_selected: true,
  };
  return { ...statusOrder, ...timeStatus };
}

export function getActualDay(complet = false) {
  const date = new Date();
  const numberDay = date.getDay();
  const semana = [
    'domingo',
    'segunda',
    'terca',
    'quarta',
    'quinta',
    'sexta',
    'sabado',
  ];
  const semanaComplet = [
    'Domingo',
    'Segunda-feira',
    'Terça-feira',
    'Quarta-feira',
    'Quinta-feira',
    'Sexta-feira',
    'Sábado',
  ];
  return complet ? semanaComplet[numberDay] : semana[numberDay];
}

export function groupItems(items, groupBy) {
  const itens = items.reduce(function (r, a) {
    const group = a[groupBy];
    r[group] = r[group] || [];
    a.total_cart = 0;
    r[group].push(a);
    return r;
  }, Object.create(null));
  return Object.values(itens);
}

export function sortBy(array, propertytoSort) {
  return array.sort((a, b) => {
    if (a[propertytoSort] > b[propertytoSort]) return 1;
    else return -1;
  });
}

export function equals(a, b) {
  return JSON.stringify(a) === JSON.stringify(b);
}

export function saveInBrowserStorage(
  sessionKey,
  value,
  isSessionStorage = true
) {
  const key = Buffer.from(sessionKey).toString('base64');
  const valueToStore = JSON.stringify(
    Buffer.from(JSON.stringify(value)).toString('base64')
  );
  if (isSessionStorage) sessionStorage.setItem(key, valueToStore);
  else localStorage.setItem(key, valueToStore);
}

export function removeItemFromSession(sessionKey, isSessionStorage = true) {
  const key = Buffer.from(sessionKey).toString('base64');
  if (isSessionStorage) sessionStorage.removeItem(key);
  else localStorage.removeItem(key);
}

export function getItemInBrowserStorage(sessionKey, isSessionStorage = true) {
  const key = Buffer.from(sessionKey).toString('base64');
  let valueInStorage;
  if (isSessionStorage)
    valueInStorage = JSON.parse(sessionStorage.getItem(key));
  else valueInStorage = JSON.parse(localStorage.getItem(key));
  if (!valueInStorage) return null;
  const buff = Buffer.from(valueInStorage, 'base64');
  return JSON.parse(buff.toString('utf-8'));
}

export function validPhoneBRL(phone) {
  const regex = /[0-9]{2}\s[0-9]{5}-[0-9]{4}/;
  return regex.test(phone);
}

export async function verifyHasUserToken() {
  const token = getItemInBrowserStorage('USER_TOKEN', false);
  if (token) return CheckIsValidToken();
  return { validToken: false, userData: null };
}

async function CheckIsValidToken() {
  const response = await verifyToken();
  if (response.success) return { validToken: true, userData: response.result };
  else return { validToken: false, userData: null };
}

export async function verifyStoreIsOpen(idStore) {
  const response = await verifyStatusStore(idStore);
  if (response.success) {
    const lastStoreStatus = getItemInBrowserStorage('LAST_STORE_STATUS');
    if (lastStoreStatus === Boolean(response.results)) {
      updateLabelStore(lastStoreStatus);
      return { error: false, result: lastStoreStatus };
    }
    saveInBrowserStorage('LAST_STORE_STATUS', Boolean(response.results));
    updateLabelStore(response.results);
    return { error: false, result: Boolean(response.results) };
  } else return { error: true, result: false };
}

export function getOpeningHours(hours) {
  let operation = { open: '', closed: '' };
  const actualDay = getActualDay();
  Object.keys(hours).forEach((el, index) => {
    switch (el) {
      case `${actualDay}_inicio`:
        operation.open = Object.values(hours)[index].substring(0, 5);
        break;
      case `${actualDay}_fim`:
        operation.closed = Object.values(hours)[index].substring(0, 5);
        break;
      default:
        break;
    }
  });
  return operation;
}

function updateLabelStore(isOpen) {
  let label = { label: isOpen ? 'Aberto' : 'Fechado', open: isOpen };
  EventEmitter.emit('update-label-store', label);
}

export function copyText(textToCopy) {
  try {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = textToCopy;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    return true;
  } catch {
    return false;
  }
}

export function deepClone(variableToClone) {
  return JSON.parse(JSON.stringify(variableToClone));
}

export function returnUniqueID(prefix = null) {
  return prefix ? `${prefix}-${uuidv4()}` : uuidv4();
}

export function verifyIsUniqueID(listToCompare, idGenerated) {
  if (listToCompare.some((id) => id === idGenerated)) {
    const newID = verifyIsUniqueID(listToCompare, returnUniqueID());
    console.error(`NOT UNIQUE: ${idGenerated} \n RETRY GENERATE ID ${newID}: `);
    return newID;
  }
  return idGenerated;
}

export async function getDeliveryPrice(CEPUser, IDStore, lastCEPSearched) {
  const pricesCalculated = getItemInBrowserStorage('CEP_TO_DELIVERY', false);

  if (Boolean(pricesCalculated)) {
    const findStoreByStoreId = pricesCalculated.findIndex(
      (el) => el.store_id === IDStore && CEPUser === el.cep
    );
    if (findStoreByStoreId >= 0) {
      const { cep, delivery_settings } = pricesCalculated[findStoreByStoreId];
      if (CEPUser === cep)
        return { error: false, deliveryInfs: delivery_settings };
    }
  }

  if (CEPUser === lastCEPSearched) {
    toast.warning('Valor da entrega para este frete já foi calculado.');
    return { error: true, deliveryInfs: null };
  }

  try {
    const response = await calculateDeliveryPrice(IDStore, CEPUser);

    if (!response.success || !Boolean(response.result)) {
      if (response.msg) {
        toast.warning(
          'O endereço cadastrado está fora do alcance máximo de entrega do estabelecimento.'
        );
        return { error: true, deliveryInfs: null };
      }
      toast.error(
        'Ocorreu um erro ao calcular o valor do frete, tente novamente ou entre em contato conosco.'
      );
      return { error: true, deliveryInfs: null };
    }

    if (response.success) {
      let addNewDelivery = pricesCalculated ? pricesCalculated : [];
      addNewDelivery.push({
        store_id: IDStore,
        cep: CEPUser,
        delivery_settings: response.result,
      });
      saveInBrowserStorage('CEP_TO_DELIVERY', addNewDelivery, false);
      return { error: false, deliveryInfs: response.result };
    }
  } catch (error) {
    toast.error(
      'Ocorreu um erro ao calcular o valor do frete, tente novamente ou entre em contato conosco.'
    );
    return { error: true, deliveryInfs: null };
  }
}

export function validateCardNumber(number) {
  const sanitizedNumber = number.replace(/\s+/g, '');
  let sum = 0;
  let shouldDouble = false;

  for (let i = sanitizedNumber.length - 1; i >= 0; i--) {
    let digit = parseInt(sanitizedNumber.charAt(i), 10);

    if (shouldDouble) {
      digit *= 2;
      if (digit > 9) {
        digit -= 9;
      }
    }

    sum += digit;
    shouldDouble = !shouldDouble;
  }

  return sum % 10 === 0;
}

export const cardsFlags = {
  visa: <IconVisaPayment />,
  mastercard: <IconMasterCardPayment />,
  amex: <IconAmexPayment />,
  elo: <IconAleloPayment />,
  hipercard: <IconHiperCardPayment />,
  diners: <IconDinersPayment />,
  discover: <IconDiscoverPayment />,
  jcb: <IconJCBPayment />,
  maestro: <IconMastroPayment />,
};

const cardPatterns = {
  elo: /^(4011\d{12}|431274\d{10}|438935\d{10}|451416\d{10}|457393\d{10}|4576\d{12}|457631\d{10}|457632\d{10}|504175\d{10}|50(4175|6699|67[0-6][0-9]|677[0-8]|9[0-8][0-9]{2}|99[0-8][0-9]|999[0-9])\d{10}|627780\d{10}|636297\d{10}|636368\d{10}|636369\d{10}|(6503[1-3])\d{11}|(6500(3[5-9]|4[0-9]|5[0-1]))\d{10}|(6504(0[5-9]|1[0-9]|2[0-9]|3[0-9]))\d{10}|(650(48[5-9]|49[0-9]|50[0-9]|51[1-9]|52[0-9]|53[0-7]))\d{10}|(6505(4[0-9]|5[0-9]|6[0-9]|7[0-9]|8[0-9]|9[0-8]))\d{10}|(6507(0[0-9]|1[0-8]))\d{10}|(6507(2[0-7]))\d{10}|(650(90[1-9]|91[0-9]|920))\d{10}|(6516(5[2-9]|6[0-9]|7[0-9]))\d{10}|(6550(0[0-9]|1[1-9]))\d{10}|(6550(2[1-9]|3[0-9]|4[0-9]|5[0-8]))\d{10}|(506(699|77[0-8]|7[1-6][0-9]))\d{10}|(509([0-9][0-9][0-9]))\d{10})/,
  hipercard: /^(606282\d{10}(\d{3})?)|(3841\d{15})$/,
  diners: /^3(0[0-5]|[68]\d)\d{11}$/,
  amex: /^3[47]\d{13}$/,
  mastercard: /^(5[1-5]\d{4}|677189)\d{10}$/,
  visa: /^4\d{12}(\d{3})?$/,
  discover: /^6(?:011|5[0-9]{2})[0-9]{12}$/,
  jcb: /^(?:2131|1800|35\d{3})\d{11}$/,
  maestro: /^(?:5[0678]dd|6304|6390|67dd)d{8,15}$/,
};

export function detectCardFlag(cardNumber) {
  if (!cardNumber) return null;
  const cleaned = cardNumber.replace(/\D/g, '');
  for (let flag in cardPatterns) {
    if (cardPatterns[flag].test(cleaned)) {
      return cardsFlags[flag];
    }
  }

  return null;
}

export function getStoreLogo(firebasePath) {
  const path = encodeURIComponent(
    `estabelecimentos/${firebasePath}/logo/store-logo.png`
  );
  return `${process.env.REACT_APP_FIREBASE_URL}/${path}?alt=media`;
}

export function getProductImage(firebasePath, idCategory, idProduct) {
  const path = encodeURIComponent(
    `estabelecimentos/${firebasePath}/produtos/${idCategory}/${idProduct}.png`
  );
  return `${process.env.REACT_APP_FIREBASE_URL}/${path}?alt=media`;
}

export const LOGO_IMAGE_URL = `${process.env.REACT_APP_FIREBASE_URL}/assets%2Flogos%2Flogo.png?alt=media`;

export const SM_LOGO_IMAGE_URL = `${process.env.REACT_APP_FIREBASE_URL}/assets%2Flogos%2Fsm-logo.png?alt=media`;

export const PRODUCT_DEFAULT_IMAGE_URL = `${process.env.REACT_APP_FIREBASE_URL}/assets%2Fproducts%2Fproduct-without-image.png?alt=media`;
