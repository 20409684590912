import React, { useContext, useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { GESTOR_TABS } from '../../../shared/StoreHeader/constants/header.constants';
import { Route, Switch } from 'react-router-dom';
import StoreHeader from '../../../shared/StoreHeader/StoreHeader';
import { StoreOwner } from '../../../Authentication/Provider/AuthenticationProvider';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

export default function Painel() {
  const history = useHistory();
  const { storeOwnerData } = useContext(StoreOwner);

  useEffect(() => {
    if (!storeOwnerData) {
      history.push('/login');
      console.error('access-denied=[PNL]');
    }
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Router>
      <StoreHeader />
      <Switch>
        {GESTOR_TABS.map((route) => (
          <Route
            exact={true}
            key={route.id}
            path={route.url}
            children={route.component}
          />
        ))}
      </Switch>
    </Router>
  );
}
