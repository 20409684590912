import React, {
  useState,
  useEffect,
  useRef,
  useContext,
  useLayoutEffect,
} from 'react';
import './Menu.scss';
import { getStore, getComplements } from '../services/Menu';
import { toast } from 'react-toastify';
import { useParams } from 'react-router';
import {
  getDeliveryPrice,
  getItemInBrowserStorage,
  getProductImage,
  groupItems,
  maskCurrencyBRL,
  maskCurrencyBRLWithoutSymbol,
  onlyNumbers,
  removeItemFromSession,
  saveInBrowserStorage,
  verifyHasUserToken,
  verifyStoreIsOpen,
} from '../Utils/Index';
import HeaderInformations from './HeaderInformations/HeaderInformations';
import ProductCard from './ProductCard/ProductCard';
import ProductDetails from './ProductDetails/ProductDetails';
import {
  ComplementsContext,
  MenuContext,
  StoreContext,
  userDataContext,
} from './MenuProvider/MenuProvider';
import Cart from './Cart/Cart';
import { getPaymentsMethod } from '../services/CartService';
import {
  useHistory,
  useLocation,
} from 'react-router-dom/cjs/react-router-dom.min';
import LoadingContent from './LoadingStoreInfos/LoadingContent';
import AddressToDelivery from './Client/Account/AddressToDelivery';
import OrdersHistory from './Client/OrdersHistory/OrdersHistory';
import EventEmitter from '../services/Event';

export default function Menu() {
  const { id } = useParams();
  const location = useLocation();

  const productRef = useRef([]);
  const history = useHistory();

  const [storeSettings, setStoreSettings] = useState(null);
  const [productStore, setProductStore] = useState(null);
  const [productStoreFilter, setProductStoreFilter] = useState(null);
  const [productDetails, setProductDetails] = useState(null);
  const [complementProduct, setComplementsProduct] = useState(null);
  const [loadedStoreInfos, setLoadedStoreInfos] = useState(false);
  const [openAuthenticatonModal, setOpenAuthenticatonModal] = useState(false);
  const [displayUserOrdersHistory, setDisplayUserOrdersHistory] =
    useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [canOrder, setCanOrder] = useState(true);

  const {
    complementsProductOpened,
    setcomplementsProductOpened,
    setComplementsProductSelected,
  } = useContext(ComplementsContext);
  const { productSelectedProvider, setProductSelectedProvider } =
    useContext(MenuContext);
  const { userData, setUserData } = useContext(userDataContext);
  const {
    storeSettingsProvider,
    setStoreSettingsProvider,
    storePayments,
    setStorePayments,
  } = useContext(StoreContext);

  useEffect(() => {
    getStoreInfos(id);
  }, [id]); //eslint-disable-line react-hooks/exhaustive-deps

  useLayoutEffect(() => {
    if (userData && storeSettings)
      callGetDeliveryPrice(userData, storeSettings);
  }, [userData, storeSettings]); //eslint-disable-line react-hooks/exhaustive-deps

  async function getStoreInfos(idStore) {
    if (storedStoreSettings()) return;
    const verifySession = location.search.includes('byRedir=1');
    if (verifySession) history.replace({ search: '' });
    const response = await getStore(idStore, verifySession);
    if (!response || !response.success) {
      history.push('/home');
      toast.error(
        'Não foi acessar a página solicitada, por favor verifique se o link está correto'
      );
      return;
    }
    const { itens, store } = response.results;
    verifyStoreIsOpen(store.codigo);
    setStoreSettings(response.results);
    getStorePaymentMethods(store.codigo);
    handleStoreProducts(itens, store.firebase_path);
    setStoreSettingsProvider({ idStore: idStore, settings: response.results });
    saveInBrowserStorage('STORE_DETAILS', {
      idStore: idStore,
      settings: response.results,
    });
    verifyUserToken();
  }

  function verifyUserToken() {
    verifyHasUserToken()
      .then((response) => {
        if (!response.validToken) return;
        setUserData(response.userData);
      })
      .catch(() => {
        removeItemFromSession('USER_TOKEN', false);
        removeItemFromSession('CEP_TO_DELIVERY', false);
        removeItemFromSession('USER_INFS', false);
        removeItemFromSession('TOKEN', false);
        removeItemFromSession('CLIENT_DATA', false);
        setUserData(null);
      });
  }

  function getStorePaymentMethods(idStore) {
    getPaymentsMethod(idStore)
      .then((payments) => {
        setStorePayments(payments);
        saveInBrowserStorage(`${idStore}_PAYMENTS_ACCEPT`, payments);
      })
      .catch(() =>
        toast.error(
          'Ocorreu um erro ao buscar os meios de pagamentos aceitos pelo estabelecimento, tente novamente ou entre em contato conosco.'
        )
      );
  }

  async function callGetDeliveryPrice(client, store) {
    const { cep } = client.address;
    const { codigo } = store.store;
    getDeliveryPrice(onlyNumbers(cep), codigo, '')
      .then((response) => {
        if (response.error) {
          console.error('DELIVERY_PRICE_SESSION');
          return;
        }
        storeSettings.store.frete = response.deliveryInfs.preco;
        setStoreSettings(storeSettings);
        setStoreSettingsProvider(storeSettings);
        EventEmitter.emit('update-delivery-price', response.deliveryInfs.preco);
      })
      .catch((error) => console.error('DELIVERY_PRICE', error));
  }

  function storedStoreSettings() {
    if (storeSettingsProvider) {
      if (storeSettingsProvider.idStore !== id) return false;
      setStoreSettings(storeSettingsProvider.settings);
      handleStoreProducts(storeSettingsProvider.settings.itens);
      return true;
    }

    const storeSettings = getItemInBrowserStorage('STORE_DETAILS');
    if (storeSettings) {
      if (storeSettings.idStore !== id) return false;
      setStoreSettings(storeSettings.settings);
      setStoreSettingsProvider({
        idStore: id,
        settings: storeSettings.settings,
      });
      handleStoreProducts(storeSettings.settings.itens);
      return true;
    }
    return false;
  }

  function handleStoreProducts(products, firebaseURL = '') {
    const items = products
      .filter((item) => item.ativo)
      .map((product) => {
        product.price_formated = maskCurrencyBRLWithoutSymbol(product.preco);
        product.isFocused = false;
        product.image = getProductImage(
          firebaseURL
            ? firebaseURL
            : storeSettingsProvider.settings.store.firebase_path,
          product.cod_categoria,
          product.codigo
        );
        return product;
      });

    setProductStore(groupItems(items, 'cod_categoria'));
    setProductStoreFilter(groupItems(items, 'cod_categoria'));
    const urlParams = new URLSearchParams(window.location.search);
    const canOrderParam = urlParams?.get('canOrder') || 'true';
    setCanOrder(JSON.parse(canOrderParam?.toLowerCase()));
    setLoadedStoreInfos(true);
  }

  function findProduct(text) {
    const temp = productStore.map((cat) => {
      return cat.filter((item) =>
        item.nome.toLowerCase().includes(text.toLowerCase())
      );
    });
    setProductStoreFilter(temp.filter((el) => el.length > 0));
  }

  function openProductDetails(product) {
    if (product.count_price) delete product.count_price;
    saveProductInProvider(product);
    setOpenModal(true);
    setProductDetails(product);
    searchForComplementByIdProductOnProvider(product.codigo);
  }

  function saveProductInProvider(product) {
    if (!productSelectedProvider.length) productSelectedProvider.push(product);
    else if (
      !productSelectedProvider.find(
        (storageProduct) => storageProduct.codigo === product.codigo
      )
    )
      productSelectedProvider.push(product);
    setProductSelectedProvider(productSelectedProvider);
  }

  function searchForComplementByIdProductOnProvider(idProduct) {
    if (complementsProductOpened.length) {
      const complementsProduct = complementsProductOpened.find(
        (complement) => complement.id_product === idProduct
      )?.complements;
      if (complementsProduct)
        confComplements(idProduct, complementsProduct, true);
      else callGetComplements(idProduct);
    } else callGetComplements(idProduct);
  }

  async function callGetComplements(idProduct) {
    const resp = await getComplements(idProduct);
    if (resp.success) {
      if (resp.result.length) confComplements(idProduct, resp.result);
      else {
        setComplementsProduct([]);
        saveComplementsInProvider(idProduct, null);
      }
    } else
      toast.error(
        'Erro ao buscar os complementos do produto, por favor entre em contato conosco.'
      );
  }

  function confComplements(idProduct, complements, isSaved = false) {
    const response = complements
      .map((groupComplement) => {
        groupComplement.required = groupComplement.qtd_minima > 0;
        groupComplement.expaded = true;
        groupComplement.id_product = idProduct;
        groupComplement.qtd_complement_selected = 0;
        groupComplement.is_valid = !groupComplement.required;
        groupComplement.label = setLabelGroupComplements(
          groupComplement.required,
          groupComplement.qtd_minima,
          groupComplement.qtd_maxima
        );
        groupComplement.items.forEach((itemGroup, index) => {
          itemGroup.formated_price = maskCurrencyBRL(itemGroup.preco);
          itemGroup.selected = false;
          itemGroup.last_item = index === groupComplement.items.length - 1;
        });
        return groupComplement;
      })
      .sort((a, b) => {
        if (!a.required && b.required) {
          return 1;
        } else return -1;
      });
    setComplementsProduct(response);
    if (!isSaved) saveComplementsInProvider(idProduct, response);
  }

  function saveComplementsInProvider(idProduct, complements) {
    complementsProductOpened.push({
      id_product: idProduct,
      complements: complements,
    });
    setcomplementsProductOpened(complementsProductOpened);
  }

  function setLabelGroupComplements(required, qtyMin, qtyMax) {
    let labelComplememt = '';
    if (required) {
      if (qtyMin === qtyMax)
        labelComplememt = `Escolha ${qtyMin} ${
          qtyMin > 1 ? 'opções' : 'opção'
        }`;
      else labelComplememt = `Escolha entre ${qtyMin} a ${qtyMax} opções`;
    } else
      labelComplememt = `Escolha até ${qtyMax} ${
        qtyMax > 1 ? 'opções' : 'opção'
      }`;
    return labelComplememt;
  }

  function clearVariables() {
    setOpenModal(false);
    setProductDetails(null);
    setComplementsProduct(null);
    setProductSelectedProvider([]);
    setComplementsProductSelected([]);
  }

  return (
    <>
      {loadedStoreInfos ? (
        <div className="vstack justify-content-start align-items-center">
          <AddressToDelivery
            openOrderHistory={() => setDisplayUserOrdersHistory(true)}
            authenticateUser={() => setOpenAuthenticatonModal(true)}
          />

          <HeaderInformations
            store={storeSettings}
            payments={storePayments}
            products={productStoreFilter}
            productsRef={productRef}
            searchProducts={(text) => findProduct(text)}
          ></HeaderInformations>

          <div className="w-100 px-2 py-3 position-relative">
            <div className="vstack">
              {productStoreFilter.length ? (
                productStoreFilter.map((category, index) => {
                  return (
                    <div
                      key={index}
                      ref={(element) =>
                        (productRef.current[index + 1] = element)
                      }
                      className="w-100 h-100 mb-2 "
                    >
                      <span className="fw-bold fs-2">
                        {category[0].categoria_nome}
                      </span>
                      <div className="store-products">
                        {category.map((product) => (
                          <ProductCard
                            key={product.codigo}
                            product={product}
                            getProductInfs={(product) =>
                              openProductDetails(product)
                            }
                          />
                        ))}
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="vstack text-center">
                  <h3 className="fw-bold fs-6">Não há produtos disponíveis.</h3>
                  <span>
                    Tente redefinir o filtro de busca ou aguarde enquanto o
                    estabelecimento adiciona novos itens ao cardápio.
                  </span>
                </div>
              )}
            </div>
          </div>

          {productDetails && (
            <ProductDetails
              productSelected={productDetails}
              completsProduct={complementProduct}
              openModal={openModal}
              handleModal={clearVariables}
              canOrderParam={canOrder}
            />
          )}

          {canOrder && (
            <Cart
              handleAuthModal={openAuthenticatonModal}
              onOpenAuthModal={() => setOpenAuthenticatonModal(false)}
            />
          )}

          {displayUserOrdersHistory && (
            <OrdersHistory close={() => setDisplayUserOrdersHistory(false)} />
          )}
        </div>
      ) : (
        <LoadingContent />
      )}
    </>
  );
}
