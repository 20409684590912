import React, { useEffect, useState } from 'react';
import { getActualDay, groupItems } from '../../../Utils/Index';
import { IconPaymentMethod } from '../../../Utils/IconPaymentMethod';

function StoreInfos({ store, hours, payments, close }) {
  const tabs = [
    { label: 'Sobre', id: 'store-tab-about' },
    { label: 'Horário', id: 'store-tab-hours' },
    { label: 'Pagamento', id: 'store-tab-payments' },
  ];

  const [storeHours, setStoreHours] = useState([]);
  const [availablePayments, setAvailablePayments] = useState([]);

  useEffect(() => {
    if (hours) handleStoreHours(hours);
    if (payments?.length) handlePayments(payments);
  }, [hours, payments]);

  function handleStoreHours(hours) {
    if (!hours) return;
    const currentDayLabel = getActualDay();
    const days = [
      'segunda',
      'terca',
      'quarta',
      'quinta',
      'sexta',
      'sabado',
      'domingo',
    ].map((dia) => ({
      day: dia.charAt(0).toUpperCase() + dia.slice(1),
      open: hours[`${dia}_inicio`].slice(0, 5),
      close: hours[`${dia}_fim`].slice(0, 5),
      isOpen: hours[`${dia}_aberto`] === 1,
      isToday: currentDayLabel === dia,
    }));
    setStoreHours(days);
  }

  function handlePayments(payments) {
    const grouped = groupItems(payments, 'pagamento_grupo');
    grouped.sort((a, b) => a.length > b.length);
    setAvailablePayments(grouped);
  }

  return (
    <div
      className="p-3 justify-content-between vstack"
      style={{ maxWidth: 460, height: 409 }}
    >
      <ul className="nav nav-tabs" id="myTab" role="tablist">
        {tabs.map((item, key) => (
          <li key={item.id} className="nav-item" role="presentation">
            <button
              className={`nav-link ${key === 0 ? 'active' : ''}`}
              id="store-tab"
              data-bs-toggle="tab"
              data-bs-target={`#${item.id}`}
              type="button"
              role="tab"
              aria-controls={item.id}
              aria-selected="true"
            >
              {item.label}
            </button>
          </li>
        ))}
      </ul>

      <div className="tab-content" id="myTabContent">
        <div
          className="tab-pane fade show active text-dark p-2"
          id="store-tab-about"
          role="tabpanel"
          aria-labelledby="store-tab"
          tabIndex="0"
        >
          <div className="vstack gap-3">
            {store?.endereco_completo ? (
              <div className="vstack">
                <span className="fw-bold fs-6">Endereço</span>
                <span className="fs-6">{store.endereco_completo}</span>
              </div>
            ) : null}
            {store?.whatsapp ? (
              <div className="vstack">
                <span className="fw-bold fs-6">WhatsApp</span>
                <span className="fs-6">{store.whatsapp}</span>
              </div>
            ) : null}
            {store?.telefone ? (
              <div className="vstack">
                <span className="fw-bold fs-6">Telefone</span>
                <span className="fs-6">{store.telefone}</span>
              </div>
            ) : null}
            {store?.email ? (
              <div className="vstack">
                <span className="fw-bold fs-6">E-mail</span>
                <span className="fs-6">{store.email}</span>
              </div>
            ) : null}
          </div>
        </div>

        <div
          className="tab-pane fade"
          id="store-tab-hours"
          role="tabpanel"
          aria-labelledby="store-tab"
          tabIndex="1"
        >
          <div className="text-dark p-2 align-items-center w-100 justify-content-center d-flex vstack gap-1 fs-6">
            {storeHours?.length ? (
              storeHours.map((item) => (
                <div
                  key={item.day}
                  className={`hstack gap-1 align-items-center ${
                    item.isToday ? 'text-primary' : 'text-dark'
                  }`}
                >
                  <span style={{ width: 90 }}>{item.day}</span>
                  <div className="hstack gap-1">
                    <span>{item.open}</span>
                    <span>ás</span>
                    <span>{item.close}</span>
                  </div>
                </div>
              ))
            ) : (
              <span>
                Não foi possível localizar os horários de funcionamento do
                estabelecimento
              </span>
            )}
          </div>
        </div>

        <div
          className="tab-pane fade"
          id="store-tab-payments"
          role="tabpanel"
          aria-labelledby="store-tab"
          tabIndex="2"
        >
          <div
            className="overflow-y-auto text-dark p-2 align-items-start w-100 justify-content-between d-flex hstack flex-wrap gap-2 fs-6"
            style={{ maxHeight: 280 }}
          >
            {availablePayments?.length ? (
              availablePayments?.map((group, key1) => {
                return (
                  <div key={key1} className="d-flex flex-column">
                    <span className="fs-6 fw-bold">
                      {group[0].pagamento_grupo}
                    </span>
                    <div className="d-flex flex-column">
                      {group.map((type, key2) => {
                        return (
                          <div key={key2} className="fs-7">
                            <IconPaymentMethod
                              idPayment={type.cod_tipo_pagto}
                            />
                            <span>{type.pagamento_tipo}</span>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })
            ) : (
              <span>
                Não foi possível localizar os métodos de pagamento aceitos pelo
                estabelecimento
              </span>
            )}
          </div>
        </div>
      </div>

      <button
        className="btn btn-outline-primary w-100 mt-3"
        onClick={() => close()}
      >
        Fechar
      </button>
    </div>
  );
}

export default StoreInfos;
