import React from 'react';
import './Plans.scss';

function CarouselPlans() {
  const plans = [
    {
      title: 'Anual',
      price: 'R$ 48,90',
      totalPrice: 'R$ 586,80',
      priceLabel: '/por mês',
      priceDescription: 'No pagamento anual',
      header_color: '#F10542',
      plan_options: [
        'Gestor de pedidos',
        'Integração com iFood',
        'Sistema de troco',
        'Raio de entrega',
        'Horário personalizado',
        'Análise de acessos',
        'Cupom de desconto',
        'Cardápio QRCode',
        'Personalizar estabelecimento',
        'Pagamento online',
        'Domínio próprio',
      ],
    },
    {
      title: 'Mensal',
      price: 'R$ 56,75',
      priceLabel: '/por mês',
      priceDescription: 'No pagamento mensal',
      header_color: '#3E3E3E',
      plan_options: [
        'Gestor de pedidos',
        'Integração com iFood',
        'Sistema de troco',
        'Raio de entrega',
        'Horário personalizado',
        'Cupom de desconto',
        'Personalizar estabelecimento',
      ],
    },
    {
      title: 'Demo',
      price: 'Grátis',
      priceLabel: '',
      priceDescription: '*promoção válida somente para novas contratações',
      header_color: '#fd983a',
      plan_options: [
        'Teste grátis por 2 meses',
        'Gestor de pedidos',
        'Sistema de troco',
        'Raio de entrega',
        'Horário personalizado',
        'Cupom de desconto',
        'Personalizar estabelecimento',
      ],
    },
  ];

  return (
    <div id="lpPlansIndicator" className="carousel slide ">
      <div className="carousel-indicators z-3 m-0 opacity-75 d-none">
        <button
          type="button"
          data-bs-target="#lpPlansIndicator"
          data-bs-slide-to="0"
          className="active"
          aria-current="true"
          aria-label="Anual Plan"
        ></button>
        <button
          type="button"
          data-bs-target="#lpPlansIndicator"
          data-bs-slide-to="1"
          aria-label="Monthly Plan"
        ></button>
        <button
          type="button"
          data-bs-target="#lpPlansIndicator"
          data-bs-slide-to="2"
          aria-label="Free test period"
        ></button>
      </div>

      <div className="carousel-inner ">
        {plans.map((plan, index) => {
          return (
            <div
              key={index}
              className={`carousel-item ${index === 0 ? 'active' : ''}`}
            >
              <div className="card rounded overflow-hidden">
                <div className="card-body p-0">
                  <div
                    className="vstack p-3"
                    style={{ background: plan.header_color }}
                  >
                    <h2 className="m-0 fs-1 fw-bold text-white text-center">
                      {plan.title}
                    </h2>
                  </div>

                  <div className="vstack  p-3">
                    <div className="hstack w-100 align-items-end justify-content-center">
                      <h2 className="m-0 fs-1 fw-bold text-red-rose text-center">
                        {plan.price}
                      </h2>
                      <span className="fs-8 fw-light text-secondary pb-1">
                        {plan.priceLabel}
                      </span>
                    </div>
                    <span className="fs-8 fw-light text-secondary text-center">
                      {plan.priceDescription}
                    </span>
                  </div>

                  <ul className="list-group list-group-flush">
                    {plan.plan_options.map((options) => {
                      return (
                        <li
                          key={options}
                          className="list-group-item p-1 fs-7 text-center text-body-emphasis"
                        >
                          {options}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
          );
        })}
      </div>

      <button
        className="carousel-control-prev z-3 p-1 p-md-2 m-0 w-auto"
        type="button"
        data-bs-target="#lpPlansIndicator"
        data-bs-slide="prev"
      >
        <span className="material-symbols-outlined bg-dark bg-opacity-50 rounded-circle">
          chevron_left
        </span>
      </button>

      <button
        className="carousel-control-next z-3 p-1 p-md-2 m-0 w-auto"
        type="button"
        data-bs-target="#lpPlansIndicator"
        data-bs-slide="next"
      >
        <span className="material-symbols-outlined bg-dark bg-opacity-50 rounded-circle">
          chevron_right
        </span>
      </button>
    </div>
  );
}

export default CarouselPlans;
