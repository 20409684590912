import React, { useEffect, useRef, useState } from 'react';
import './SupportModal.scss';
import { IconChat, IconClose } from '../../assets/Icons';
import DocumentationsFilter from '../DocumentationsFilter/DocumentationsFilter';
import { Form } from '@unform/web';
import Textarea from '../../shared/Textarea/Textarea';
import * as Yup from 'yup';
import { getItemInBrowserStorage } from '../../Utils/Index';
import { toast } from 'react-toastify';
import InputV2 from '../../shared/InputV2/InputV2';
import ReCAPTCHA from 'react-google-recaptcha';
import { contactSellers } from '../../services/LandingPage';

function SupportModal({ supportFormOnly = false }) {
  const formRef = useRef();
  const recaptchaRef = useRef();

  const [displayOnlySupportForm, setDisplayOnlySupportForm] = useState(false);
  const [openedModal, setOpenedModal] = useState(false);
  const [displaySupportForm, setDisplaySupportForm] = useState(false);
  const [disableEmissionButton, setDisableEmissionButton] = useState(false);

  useEffect(() => {
    setDisplayOnlySupportForm(false);
  }, [supportFormOnly]);

  useEffect(() => {
    setDisableEmissionButton(false);
  }, [displayOnlySupportForm, displaySupportForm]);

  useEffect(() => {
    if (openedModal) return;
    setDisplayOnlySupportForm(false);
    setDisplaySupportForm(false);
    setDisableEmissionButton(false);
    if (formRef.current) formRef.current.reset();
  }, [openedModal]);

  async function verifyFormData(field) {
    try {
      const dataToContact = Yup.object().shape({
        subject: Yup.string().required('Informe o assunto que deseja tratar.'),
        descricao: Yup.string().required(
          'Informe uma descrição para o seu suporte.'
        ),
      });
      await dataToContact.validate(field, { abortEarly: false });
      formRef.current.setErrors({});
      callSendSupportMessage();
      return true;
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errorMessages = {};
        error.inner.forEach((error) => {
          errorMessages[error.path] = error.message;
        });
        formRef.current.setErrors(errorMessages);
      }
      return false;
    }
  }

  async function getRecaptcharToken() {
    const token = await recaptchaRef.current.executeAsync();
    if (!token) {
      toast.error(
        'Ocorreu um erro ao validar o captcha, entre em contato com o nosso suporte'
      );
      return '';
    }
    return token;
  }

  function callSendSupportMessage() {
    const store = getItemInBrowserStorage('CLIENT_DATA', false);

    if (!store) {
      toast.error(
        'Não foi possível localizar os dados do estabelecimento, por favor realize o login novamente!'
      );
      return;
    }
    const { nome_estabelecimento, cod_estabelecimento, email, cod_usuario } =
      store;
    const { subject, descricao } = formRef.current.getData();
    const params = {
      name: nome_estabelecimento,
      subject: subject,
      message: descricao,
      email,
      token: '',
      type_establishment: `cod_usuario=[${cod_usuario}] | cod_estabelecimento=[${cod_estabelecimento}]`,
      phone: 'N/A',
      city: 'N/A',
    };
    handleSupportInfos(params);
  }

  function handleSupportInfos() {
    const { name, email, description } = formRef.current.getData();
    const params = {
      name,
      city: 'N/A',
      email,
      type_establishment: 'N/A',
      phone: 'N/A',
      subject: 'Chamado página de suporte',
      message: description,
      token: '',
    };
    getRecaptcharToken().then((token) => {
      params.token = token;
      openSupportTicket(params);
    });
  }

  function openSupportTicket(params) {
    contactSellers(params)
      .then((response) => {
        console.log('response', response);
      })
      .catch((error) => {
        console.error('error', error);
      });
  }

  return (
    <div className="support-modal">
      {!openedModal && (
        <button
          onClick={() => setOpenedModal(!openedModal)}
          className={`support-modal__button${
            openedModal ? '-remove-drop-shadow' : ''
          }`}
        >
          <IconChat color={'#FFFFFF'} border={'#FFFFFF'} size={24} />
        </button>
      )}

      <div
        className={`support-modal__content ${
          openedModal ? 'opened' : 'closed'
        }`}
        style={{
          height:
            (displayOnlySupportForm || (displaySupportForm && openedModal)) &&
            'auto',
        }}
      >
        <div className="support-modal__content__header">
          <div className="support-modal__content__header__group">
            <h2 className="support-modal__content__header__group__title">
              Suporte
            </h2>
            <button
              type={'button'}
              className="support-modal__content__header__group__close-modal-button"
              onClick={() => setOpenedModal(!openedModal)}
            >
              {' '}
              <IconClose color={'#FFFFFF'} />{' '}
            </button>
          </div>
          <p className="support-modal__content__header__description">
            Olá, como podemos te ajudar ?
          </p>
          {!displayOnlySupportForm && !displaySupportForm && (
            <>
              <p className="support-modal__content__header__description">
                Busque por palavras chaves para visualizar as nossas
                documentações.
              </p>
              <p style={{ marginBottom: 0 }}>
                Caso precise
                <button
                  type={'button'}
                  className="support-modal__content__header__open-form-button"
                  onClick={() => setDisplaySupportForm(true)}
                >
                  {' '}
                  clique aqui{' '}
                </button>
                para entrar em contato com o nosso suporte.
              </p>
            </>
          )}
          {displaySupportForm && (
            <>
              <p className="support-modal__content__header__description">
                Preencha os campos para entrar em contato com nosso suporte,
                retornaremos seu contato através do e-mail informado.
              </p>
              <p style={{ marginBottom: 0 }}>
                Caso precise
                <button
                  type={'button'}
                  className="support-modal__content__header__open-form-button"
                  onClick={() => setDisplaySupportForm(false)}
                >
                  {' '}
                  clique aqui{' '}
                </button>
                para visualizar nossa documentações.
              </p>
            </>
          )}
        </div>
        {!displayOnlySupportForm && !displaySupportForm && (
          <div className="support-modal__content__search-doc">
            <DocumentationsFilter disableHeader={true} useModalColors={true} />
          </div>
        )}
        {displayOnlySupportForm ||
          (displaySupportForm && (
            <Form
              ref={formRef}
              onSubmit={verifyFormData}
              className="vstack p-2 gap-3"
            >
              <InputV2
                label="Assunto *"
                name="subject"
                type="text"
                placeholder="Informe o assunto"
                maxLength={50}
              />

              <Textarea
                label="Descrição"
                name="descricao"
                type="text"
                maxLength={250}
                labelStyle={{ width: '100%' }}
                className="dl-textarea"
              />
              <button
                type="submit"
                className={`btn btn-primary w-100 mt-3 ${
                  disableEmissionButton ? 'pe-none opacity-50' : ''
                }`}
                disabled={disableEmissionButton}
                maxLength={200}
              >
                Enviar suporte
              </button>
            </Form>
          ))}
      </div>
      <ReCAPTCHA
        ref={recaptchaRef}
        size="invisible"
        sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_LP}
      ></ReCAPTCHA>
    </div>
  );
}

export default SupportModal;
