import React, { useEffect, useState } from 'react';
import './HeaderInformations.scss';
import {
  PRODUCT_DEFAULT_IMAGE_URL,
  getActualDay,
  getStoreLogo,
  maskCurrencyBRL,
  maskPhoneBR,
} from '../../Utils/Index';
import SpringModal from '../../shared/Modal/Modal';
import StoreInfos from './components/StoreInfos';
import SearchProducts from './components/SearchProducts';
import CategoryTab from '../CategoryTAb/CategoryTab';
import EventEmitter from '../../services/Event';

export default function HeaderInformations({
  store,
  payments,
  searchProducts,
  isStoreMenu = true,
  products,
  productsRef,
}) {
  const [infos, setInfos] = useState(null);
  const [displayStoreInfos, setDisplayStoreInfos] = useState(false);
  const [storeLogo, setStoreLogo] = useState(PRODUCT_DEFAULT_IMAGE_URL);
  const [isScrolled, setIsScrolled] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [storeStatusLabel, setStatusStoreLabel] = useState({
    label: 'Fechado',
    open: false,
  });
  const [deliveryScheduling, setDeliveryScheduling] = useState({
    delivery: '',
    withdraw: '',
  });

  useEffect(() => {
    if (!store) return;
    handleStoreInfos(store.store);
    handleDeliveryScheduling(store.hours_delivery);
    if (store.store.logo_store) setStoreLogo(store.store.logo_store);
  }, [store]); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const handleScroll = () => setIsScrolled(window.scrollY > 10);
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
      EventEmitter.off('update-label-store');
      EventEmitter.off('update-delivery-price');
    };
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  function handleStoreInfos(infos) {
    const {
      telefone,
      logradouro,
      logradouro_nro,
      cidade,
      uf,
      frete,
      firebase_path,
    } = infos;
    infos.telefone = maskPhoneBR(telefone);
    infos.endereco_completo = `${logradouro}, ${logradouro_nro} - ${cidade}/${uf}`;
    infos.valor_entrega = frete ? maskCurrencyBRL(frete) : 'Grátis';
    infos.logo_store = getStoreLogo(firebase_path);
    setInfos(infos);

    EventEmitter.on('update-label-store', (label) =>
      setStatusStoreLabel(label)
    );
    EventEmitter.on('update-delivery-price', (price) => {
      infos.valor_entrega = maskCurrencyBRL(price);
      setInfos(infos);
    });
  }

  function handleDeliveryScheduling(hours) {
    const today = getActualDay();
    if (hours[`${today}_aberto`]) {
      setDeliveryScheduling({
        delivery: formatTimeToMinOrHours(hours[`${today}_delivery`]),
        withdraw: formatTimeToMinOrHours(hours[`${today}_retirada`]),
      });
    }
  }

  function formatTimeToMinOrHours(timeString) {
    const [hours, minutes] = timeString.split(':').map(Number);
    if (hours === 0) return `${minutes}min`;
    else return `${hours}h${minutes}min`;
  }

  return (
    <>
      <div
        style={{ height: isStoreMenu ? 235 : 120 }}
        className={`store-header w-100 top-0  ${
          isScrolled ? 'store-header-scrolled' : ''
        }`}
      >
        <div
          className={`store-header__content ${
            isStoreMenu ? '' : 'store-header__content-conclue-order'
          } position-fixed z-3 w-100 bg-primary align-items-center bg-primary gap-2 justify-content-center p-2 d-flex w-100`}
        >
          <div className="vstack gap-2 w-100 max-w-fit">
            <div className="w-100 gap-2 hstack align-items-center justify-content-center">
              <div className="position-relative">
                <img
                  width={isScrolled ? 40 : 80}
                  height={isScrolled ? 40 : 80}
                  src={storeLogo}
                  alt="Logo estabelecimento"
                  className="overflow-hidden rounded-1 object-fit-contain"
                  onError={() => setStoreLogo(PRODUCT_DEFAULT_IMAGE_URL)}
                />
                {!isScrolled && (
                  <div className="position-absolute bottom-0 bg-dark w-100 hstack justify-content-center bg-black bg-opacity-50">
                    <span className="text-white fs-8">
                      {storeStatusLabel.label}
                    </span>
                  </div>
                )}
              </div>
              <h1 className={`fw-bold text-white m-0 p-0 fs-3`}>
                {infos?.nome}
              </h1>

              {isScrolled && isStoreMenu && (
                <button
                  className="btn btn-primary-fill hstack m-0 mx-3 p-0 text-white"
                  onClick={() => setShowSearch(!showSearch)}
                >
                  <span className="material-symbols-outlined">search</span>
                </button>
              )}
            </div>

            {!isScrolled && (
              <div className="hstack gap-2 align-items-center justify-content-between">
                <div className="hstack gap-2">
                  <div className="vstack fs-7 text-white">
                    <span className="fw-bold">Entrega</span>
                    <span>{deliveryScheduling.delivery}</span>
                  </div>

                  <div className="vstack fs-7 text-white">
                    <span className="fw-bold">Taxa delivery</span>
                    <span>{infos?.valor_entrega}</span>
                  </div>
                </div>

                {infos?.aceita_retirada ? (
                  <div className="vstack fs-7 text-white">
                    <span className="fw-bold">Retirada</span>
                    <span>{deliveryScheduling.withdraw}</span>
                  </div>
                ) : null}

                <button
                  className="btn btn-outline-light max-w-fit p-0 px-1 fs-7"
                  onClick={() => setDisplayStoreInfos(true)}
                >
                  Ver mais
                </button>
              </div>
            )}
          </div>
        </div>

        {isStoreMenu && (
          <div
            className={`position-fixed w-100 z-2 bg-white 
            store-header-categories-visible 
            ${showSearch ? '' : 'shadow-sm'}
            ${
              isScrolled
                ? 'store-header-categories-show'
                : 'store-header-categories-hide'
            }
          `}
          >
            <CategoryTab products={products} productsRef={productsRef} />
          </div>
        )}

        {isStoreMenu && (
          <div
            className={`position-fixed z-1 top-0 w-100 search-store-product shadow-sm ${
              isScrolled
                ? showSearch
                  ? 'search-store-product-show'
                  : 'search-store-product-hide'
                : 'search-store-product-visible'
            }`}
          >
            <SearchProducts search={(text) => searchProducts(text)} />
          </div>
        )}
      </div>

      <SpringModal
        handleOpen={displayStoreInfos}
        handleClose={() => setDisplayStoreInfos(false)}
      >
        <StoreInfos
          store={store?.store}
          hours={store?.hours_store}
          payments={payments}
          close={() => setDisplayStoreInfos(false)}
        />
      </SpringModal>
    </>
  );
}
