import React from 'react';
import './LandingPage.scss';
import Header from './Header/Header';
import Banner from './Banner/Banner';
import Plans from './Plans/Plans';
import HowItWorks from './HowItWorks/HowItWorks';
import AboutUs from './AboutUs/AboutUs';
import ContactUs from './ContactUs/ContactUs';
import Footer from './Footer/Footer';
import CarouselBanner from './CarouselBanner';

export default function LandingPage() {
  return (
    <div className="z-3">
      <div id="start">
        <Header />
      </div>
      <Banner />
      <CarouselBanner />
      <div id="plans">
        <Plans />
      </div>
      <div id="howWork">
        <HowItWorks />
      </div>
      <div id="about">
        <AboutUs />
      </div>
      <div id="contacts">
        <ContactUs />
      </div>
      <Footer />
    </div>
  );
}
