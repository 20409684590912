import React from 'react';

function FAQ() {
  const FAQ_ITENS = [
    {
      title: 'Quais são os planos disponíveis e os custos?',
      text: '<p>Nossa plataforma oferece <strong>2 planos</strong>: a <strong>assinatura anual</strong>, atualmente por <strong>R$ 51,90</strong>, que pode ser parcelada em até <strong>12x sem juros</strong>, e a <strong>assinatura mensal</strong>, que custa <strong>R$ 63,60</strong>. Para mais detalhes, <a href="/home#plans">clique aqui</a> e veja nossos planos.</p>',
    },

    {
      title: 'Quem pode utilizar o Deliver.legal ?',
      text: '<p>Qualquer <strong>pessoa ou empresa</strong> que deseje realizar <strong>vendas online</strong>. Nossa plataforma é <strong>totalmente customizável</strong>, permitindo adicionar os produtos e categorias que você quiser. Faça seu <strong>cadastro agora</strong> e aproveite o período de teste gratuito de <strong>2 meses</strong>.</p>',
    },

    {
      title: 'Como funciona o período de teste gratuito?',
      text: '<p>O período de teste é válido por <strong>2 meses</strong> e está disponível apenas para <strong>novos usuários</strong>. Basta criar uma <strong>nova conta</strong> e, ao acessá-la, você receberá uma notificação confirmando a <strong>ativação do teste gratuito</strong>! Durante esse período, não será necessário fornecer <strong>dados de pagamento</strong>.</p>',
    },

    {
      title: 'Como posso entrar em contato com a equipe de suporte?',
      text: '<p>Visite nossa <a href="/suporte">página de suporte</a> e escolha o melhor canal para falar com nossa equipe.</p>',
    },

    {
      title: 'Onde posso encontrar ajuda para usar a plataforma?',
      text: '<p>Acesse nosso <a href="/suporte">material de ajuda</a> dedicado aos estabelecimentos e siga o <strong>passo a passo</strong> para aprender a <strong>criar, personalizar e utilizar</strong> as funcionalidades da plataforma.</p>',
    },
  ];

  return (
    <div className="vstack w-100 justify-content-center align-items-center py-2">
      <h2 className="fw-bold text-center">Principais dúvidas</h2>
      <div className="w-100" style={{ maxWidth: 800 }}>
        <div className="accordion" id="faqItens">
          {FAQ_ITENS.map((item, index) => {
            return (
              <div key={index} className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    aria-expanded="false"
                    data-bs-target={`#collapse${index}`}
                    aria-controls={`collapse${index}`}
                  >
                    {item.title}
                  </button>
                </h2>
                <div
                  id={`collapse${index}`}
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div
                    className="accordion-body"
                    dangerouslySetInnerHTML={{ __html: item.text }}
                  ></div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default FAQ;
