import React from 'react';
import './HowItWorks.scss';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

export default function HowItWorks() {
  const history = useHistory();
  function handleToSection() {
    history.push('/login');
    history.push('/criar-conta');
  }

  return (
    <div className="how-it-works">
      <div className="how-it-works-container">
        <div className="how-it-works__informative_texts">
          <h3 className="how-it-works__informative_texts-title">
            Como funciona
          </h3>
          <span className="how-it-works__informative_texts-subtitle">
            Veja como é fácil e rápido de configurar sua loja.
          </span>
          <span className="how-it-works__informative_texts-subtitle">
            Em apenas 3 etapas você já pode disponibilizar seu cardápio ou
            catálogo para o mundo.
          </span>
        </div>
        <div className="how-it-works-align-steps">
          <div className="how-it-works__content">
            <div className="how-it-works__content__step">
              <span className="how-it-works__content__step-number">1</span>
              <h5 className="how-it-works__content__step-label">
                Cadastre seus produtos
              </h5>
            </div>

            <img
              src={`${process.env.REACT_APP_FIREBASE_URL}/assets%2Flanding_page%2Flanding_page_step1.png?alt=media`}
              loading="lazy"
              alt=""
            />
          </div>
          <span className="material-symbols-outlined fs-3 text-primary">
            chevron_right
          </span>
          <div className="how-it-works__content">
            <div className="how-it-works__content__step">
              <span className="how-it-works__content__step-number">2</span>
              <h5 className="how-it-works__content__step-label">
                Ajuste de horários e raio de entrega
              </h5>
            </div>
            <img
              src={`${process.env.REACT_APP_FIREBASE_URL}/assets%2Flanding_page%2Flanding_page_step2.png?alt=media`}
              loading="lazy"
              alt=""
            />
          </div>
          <span className="material-symbols-outlined fs-3 text-primary">
            chevron_right
          </span>
          <div className="how-it-works__content">
            <div className="how-it-works__content__step">
              <span className="how-it-works__content__step-number">3</span>
              <h5 className="how-it-works__content__step-label">
                Publique sua loja
              </h5>
            </div>
            <img
              src={`${process.env.REACT_APP_FIREBASE_URL}/assets%2Flanding_page%2Flanding_page_step3.png?alt=media`}
              loading="lazy"
              alt=""
            />
          </div>
        </div>

        <div className="how-it-works__button">
          <button
            type="button"
            className="btn btn-primary fw-bold px-5 py-2"
            onClick={() => handleToSection()}
          >
            Experimente grátis
          </button>
        </div>
      </div>
    </div>
  );
}
